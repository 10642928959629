
import { supportedColors } from "../app-consts/appConstants";
import {TRANSLATION_CONSTANTS} from "../app-consts/translations";
import { determineLocale, getTranslatedTextByLocale } from "../contexts/LocaleContext";
import titleCase from "./titleCase";

const experimentalServiceText = (serviceText) => {
  switch(serviceText) {
    case 'Tow':
      return "I need a tow";
    case 'Lockout':
      return "I'm locked out";
    case 'Fuel Delivery':
      return "I'm out of gas";
    case 'Tire Change':
      return "I have a flat tire";
    case 'Jump Start':
      return "My car won't start";
    default:
      return serviceText;
  }
}

const serviceTranslatedText = (serviceText) => {
    switch(serviceText) {
      case 'Tow':
        return TRANSLATION_CONSTANTS.TOW;
      case 'Lockout':
      case 'Lock-out':
        return TRANSLATION_CONSTANTS.LOCKOUT;
      case 'Fuel Delivery':
        return TRANSLATION_CONSTANTS.FUEL_DELIVERY;
      case 'Tire Change':
        return TRANSLATION_CONSTANTS.TIRE_CHANGE;
      case 'Jump Start':
      case 'Start':
        return TRANSLATION_CONSTANTS.JUMP_START;
      default:
        return serviceText;
    }
  };

const colorTranslatedText = (colorText) => {
  switch(colorText) {
    case 'Black':
      return TRANSLATION_CONSTANTS.BLACK;
    case 'Blue':
      return TRANSLATION_CONSTANTS.BLUE;
    case 'Brown':
      return TRANSLATION_CONSTANTS.BROWN;
    case 'Burgundy':
      return TRANSLATION_CONSTANTS.BURGUNDY;
    case 'Gold':
      return TRANSLATION_CONSTANTS.GOLD;
    case 'Green':
      return TRANSLATION_CONSTANTS.GREEN;
    case 'Grey':
      return TRANSLATION_CONSTANTS.GREY;
    case 'Orange':
      return TRANSLATION_CONSTANTS.ORANGE;
    case 'Purple':
      return TRANSLATION_CONSTANTS.PURPLE;
    case 'Red':
      return TRANSLATION_CONSTANTS.RED;
    case 'Silver':
      return TRANSLATION_CONSTANTS.SILVER;
    case 'White':
      return TRANSLATION_CONSTANTS.WHITE;
    case 'Yellow':
      return TRANSLATION_CONSTANTS.YELLOW;
    default:
      return colorText;
  }
};

const statusTranslatedText = (statusText) => {
  switch(statusText) {
    case 'closed':
      return TRANSLATION_CONSTANTS.CLOSED;
    case 'closing soon':
      return TRANSLATION_CONSTANTS.CLOSING_SOON;
    default:
      return statusText;
  }
};

const errorTranslatedText = (errorText) => {
  switch(errorText) {
    case "It looks as though you haven't signed up for roadside benefits yet. You can either pay for just today's service, or call us to ask about a membership.":
      return TRANSLATION_CONSTANTS.MEMBER_NOT_FOUND;
    case "You’ve used up all of your benefits. To pay for today’s service, choose from the options below.":
      return TRANSLATION_CONSTANTS.USED_ALL_YOUR_BENEFITS_TO_PAY;
    case "Your membership is not yet eligible for roadside coverage due to 2 day wait period. To pay for today's service, choose from the options below":
      return TRANSLATION_CONSTANTS.MEMBERSHIP_2_DAY_WAIT_PERIOD;
    case "Your coverage  was cancelled but we can still help. Use our pay-per-use option to get roadside help today.":
      return TRANSLATION_CONSTANTS.CONTRACT_CANCELLED_MESSAGE;
    case "Your coverage expired but we can still help. Use our pay-per-use option to get roadside help today.":
      return TRANSLATION_CONSTANTS.CONTRACT_EXPIRED_MESSAGE;
    case "Your coverage is not active yet but we can still help. Use our pay-per-use options to get roadside help today.":
      return TRANSLATION_CONSTANTS.CONTRACT_NOT_ACTIVE_YET_MESSAGE;
    case "Your coverage requires you to prepay for your roadside service and submit a claim for reimbursement.":
      return TRANSLATION_CONSTANTS.REIMBURSEMENT_MESSAGE;
    default:
      return errorText;
  }
};

const errorMessageTranslatedText = (errorText) => {
  switch(errorText) {
    case 'Please enter your 9 or 10 digit membership number.':
      return TRANSLATION_CONSTANTS.ERROR_MEMBERSHIP_NUMBER;
    case 'Please enter a policy number starting with 4 characters and ending with 9 digits. Example: XXXX123456789':
      return TRANSLATION_CONSTANTS.ERROR_POLICY_NUMBER;
    case 'Please enter only the 8 or 9 digits on your rental agreement number':
      return TRANSLATION_CONSTANTS.ERROR_RA_NUMBER;
    default:
      return errorText;
  }
};

const getTranslatedOperatingHoursText = (operatingHoursText, getTranslatedText) => {
  if (operatingHoursText?.includes("Closing Soon • Open until")) {
    let operatingHoursTextSplit = operatingHoursText?.split(" ");
    let operatingHours = operatingHoursTextSplit[5] + " " + operatingHoursTextSplit[6];
    return getTranslatedText(TRANSLATION_CONSTANTS.CLOSING_SOON_OPEN_UNTIL).replace("{time}", operatingHours);
  } else if (operatingHoursText?.includes("Open until")) {
    let operatingHoursTextSplit = operatingHoursText?.split(" ");
    let operatingHours = operatingHoursTextSplit[2] + " " + operatingHoursTextSplit[3];
    return getTranslatedText(TRANSLATION_CONSTANTS.OPEN_UNTIL).replace("{time}", operatingHours);
  } else if (operatingHoursText?.includes("Closed")) {
    return getTranslatedText(TRANSLATION_CONSTANTS.TOW_DESTINATION_CLOSED);
  } else return operatingHoursText;
};

const getTranslatedColors = getTranslatedText => {
  return [...supportedColors].map(color => {
    return {...color, text: getTranslatedText(colorTranslatedText(color.text))}
  })
}

const getTextByUnits = (units = "mile", milesKey = "", kilometerKey = "") => {
  if(!milesKey || !kilometerKey) {
    return "";
  }
  const locale = determineLocale();
  if(units === "mile") {
    return titleCase(getTranslatedTextByLocale(locale, milesKey))
  }
  if(units === "kilometer") {
    return titleCase(getTranslatedTextByLocale(locale, kilometerKey))
  }
}

export {
  serviceTranslatedText,
  colorTranslatedText,
  statusTranslatedText,
  errorTranslatedText,
  errorMessageTranslatedText,
  getTranslatedOperatingHoursText,
  experimentalServiceText,
  getTranslatedColors,
  getTextByUnits
};
